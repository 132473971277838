







































































































import { SfButton } from "@storefront-ui/vue";

import {
  computed,
  ref,
  defineComponent,
  useRouter,
  useContext,
  onMounted,
  useFetch,
  watch,
} from "@nuxtjs/composition-api";
import { useCategory } from "~/modules/catalog/category/composables/useCategory";
import { useUiState } from "~/composables";
import { useCart } from "~/modules/checkout/composables/useCart";
import { useWishlist } from "~/modules/wishlist/composables/useWishlist";
import { useUser } from "~/modules/customer/composables/useUser";
import { useWishlistStore } from "~/modules/wishlist/store/wishlistStore";
import type {
  ProductInterface,
  CategoryInterface,
} from "~/modules/GraphQL/types";
import { Icon } from "@iconify/vue2";
import { useAddToCartPopup } from "~/stores/openAddToCartPopup";
export default defineComponent({
  components: {
    SfButton,
    Icon,
    StoreSwitcher: () => import("~/components/StoreSwitcher.vue"),
    SearchBar: () => import("~/components/Header/SearchBar/SearchBar.vue"),
    SearchResults: () =>
      import(
        /* webpackPrefetch: true */ "~/components/Header/SearchBar/SearchResults.vue"
      ),
  },
  setup() {
    const router = useRouter();
    const { app } = useContext();
    const { toggleCartSidebar, toggleWishlistSidebar } = useUiState();
    const { isAuthenticated } = useUser();
    const { cart, load: loadCart } = useCart();
    const { loadItemsCount: loadWishlistItemsCount } = useWishlist();

    const isSearchOpen = ref(false);
    const productSearchResults = ref<ProductInterface[] | null>(null);
    const categorySearchResults = ref<CategoryInterface[] | null>(null);
    const brandsResult = ref(null);

    const wishlistStore = useWishlistStore();
    const wishlistItemsQty = computed(() => wishlistStore.wishlist?.items_count ?? 0);

    const wishlistHasProducts = computed(() => wishlistItemsQty.value > 0);

    let searchVal = ref("");
    const onSearchValue = (searchValue) => {
      searchVal.value = searchValue;
    };

    const cartTotalItems = computed(() => cart.value?.total_quantity ?? 0);

    const animateItemNumber = ref(false);
    const goToCart = async () => {
      cartPopupVisible.value = false;
      await router.push(app.localeRoute({ name: "cart" }));
    };
    let cartPopupVisible = ref(false);
    let hoverPopup = ref(null);
    const hideMe = (val, time) => {
      hoverPopup.value = setTimeout(() => (val.value = false), time);
    };
    let addToCartPopop = useAddToCartPopup();
    const stopPopup = () => {
      if (hoverPopup.value) {
        clearTimeout(hoverPopup.value);
      }
    };
    const continuePopup = () => {
      cartPopupVisible.value = true;
      hideMe(cartPopupVisible, 5000);
    };
    watch(
      cartTotalItems,
      (val, oldVal) => {
        if (((oldVal === 0 && val === 0) || oldVal < val) && addToCartPopop.openPopup) {
          animateItemNumber.value = true;
          cartPopupVisible.value = true;
          hideMe(animateItemNumber, 1000);
          hideMe(cartPopupVisible, 5000);
        }
      },
      { immediate: false, deep: true }
    );

    onMounted(async () => {
      if (cart !== null && cart.value && !cart.value.id) {
        await loadCart();
        animateItemNumber.value = false;
        cartPopupVisible.value = false;
      }
      await loadWishlistItemsCount();
    });

    let totalRes = ref(null);
    const getTotalResults = (setTotalResults) => {
      totalRes.value = setTotalResults;
    };

    const discount = computed(() => {
      if (cart?.value?.prices?.discounts) {
        return cart?.value?.prices?.discounts[0]?.amount?.value ?? 0;
      } else {
        return 0;
      }
    });
    const totals = computed(() => {
      if (discount.value) {
        return cart?.value?.prices?.subtotal_including_tax.value - discount.value;
      } else {
        return cart?.value?.prices?.subtotal_including_tax.value ?? 0;
      }
    });

    return {
      getTotalResults,
      totalRes,
      cartTotalItems: computed(() => cart?.value?.total_quantity ?? 0),
      totals: totals,
      isAuthenticated,
      isSearchOpen,
      productSearchResults,
      categorySearchResults,
      toggleCartSidebar,
      toggleWishlistSidebar,
      wishlistHasProducts,
      wishlistItemsQty,
      brandsResult,
      animateItemNumber,
      onSearchValue,
      searchVal,
      goToCart,
      cartPopupVisible,
      stopPopup,
      continuePopup,
    };
  },
});
