



























import { defineComponent } from "@nuxtjs/composition-api";
import { SfNotification } from "@storefront-ui/vue";
import { SfOverlay } from "@storefront-ui/vue";
import { Icon } from "@iconify/vue2";
import useUiAlert from "~/composables/useUiAlert";

export default defineComponent({
  name: "AlertBar",
  components: {
    SfNotification,
    SfOverlay,
    Icon,
  },
  setup() {
    const { alerts } = useUiAlert();

    return {
      alerts,
    };
  },
});
